import { grey } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

const primaryColor = {
  50: "#e1e8ea",
  100: "#b4c5cb",
  200: "#829fa9",
  300: "#4f7987",
  400: "#2a5c6d",
  500: "#043f53",
  600: "#03394c",
  700: "#033142",
  800: "#022939",
  900: "#011b29",
  A100: "#63baff",
  A200: "#30a3ff",
  A400: "#008cfc",
  A700: "#007ee3",
  contrastDefaultColor: "light",
};

const accentColor = {
  50: "#e0faf4",
  100: "#b3f3e4",
  200: "#80ebd2",
  300: "#4de2c0",
  400: "#26dcb3",
  500: "#00d6a5",
  600: "#00d19d",
  700: "#00cc93",
  800: "#00c68a",
  900: "#00bc79",
  A100: "#e5fff4",
  A200: "#b2ffdf",
  A400: "#7fffcb",
  A700: "#65ffc0",
  contrastDefaultColor: "light",
};

// A custom theme for this app
export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 980,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      ...primaryColor,
    },
    secondary: {
      main: accentColor[500],
      ...accentColor,
    },
    error: {
      main: "#ca4c3e",
    },
    background: {
      default: "#ffffff",
      navigationMenu: "#f6f4f4",
      placeholder: "#f1f1f1",
      highlight: grey[50],
      tabColor: grey[400],
      inputBackground: grey[200],
    },
    text: {
      primary: "#272727",
      secondary: "#989494",
    },
  },
  typography: {
    fontFamily: [
      "Rubik",
      "sans-serif",
    ].join(","),
    body1: {
      marginBottom: "1rem",
      fontSize: "18px",
      lineHeight: "1.8rem",
      color: "#043f53",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      contained: {
        // Some CSS
        backgroundColor: "#00ac8c",
        width: 216,
        height: 40,
        borderRadius: 8,
        color: "#fff",
        fontSize: 12,
        lineHeight: "22px",
        fontWeight: 400,
        paddingTop: 7,
      },
    },
    MuiLink: {
      underlineAlways: {
        textDecoration: "underline",
        color: "#00ac8c",
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#fff",
      },
      root: {
        boxShadow: "#ccc 3px 3px 5px 3px",
      }
    }
  },
});
