import React from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { red } from "@material-ui/core/colors";

const PARTNER_CODE = process.env.REACT_APP_WEBSITE_PARTNER_CODE;
const PARTNER_NAME = process.env.REACT_APP_WEBSITE_PARTNER_NAME;

const ErrorPage = ({ isNoCodeError }) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justify="space-between"
    className="mb-8"
  >
    <Grid item xs={12} style={{ textAlign: "center", margin: 16 }}>
      {isNoCodeError
        ? <img src={`/assets/images/${PARTNER_CODE}/logo.png`} alt="logo" style={{ display: "inline", maxWidth: 200 }} />
        : <CancelIcon style={{ color: red[900], fontSize: 40 }} className="mb-8" />}
      {isNoCodeError && <Typography style={{ fontSize: 30, marginBottom: 44, marginTop: 20 }}>Welcome to {PARTNER_NAME} members</Typography>}
      {!isNoCodeError && <Typography style={{ fontSize: 30, marginBottom: 44, marginTop: 20 }}>Something seems to have gone wrong</Typography>}
      <Typography style={{ fontSize: 20 }}>
        If you&apos;re trying to access Snug, please visit the
        {" "}
        <Link
          href={process.env.REACT_APP_WEBSITE_HELP_URL}
          underline="always"
        >
          Snug information page
        </Link>
        .
      </Typography>
    </Grid>
  </Grid>
);

export default ErrorPage;
