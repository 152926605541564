import React from "react";
import { AppBar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    height: "auto",
    padding: "10px",
    textAlign: "center",
  },
  tagLine: {
    color: theme.palette.primary[500],
    padding: "7px 0 0",
  },
  [theme.breakpoints.up("md")]: {
    header: {
      height: "60px",
      textAlign: "initial",
    },
    tagLine: {
      padding: "7px 10px 10px 10px",
      float: "right",
      display: "block",
    },
  },
}));

const ScreenHeader = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" >
      <div className={classes.header}>
        <img style={{ display: "initial" }} src="/assets/images/snugGrey.svg" width="167" height="39" alt="" />
        <div className={classes.tagLine}>
          <Typography variant="caption" style={{ fontSize: "18px" }}>All your health in one place!</Typography>
        </div>
      </div>
    </AppBar>
  );
};
export default ScreenHeader;
