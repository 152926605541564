export const codeKey = process.env.REACT_APP_B2C_CODE_KEY
export const idKey = process.env.REACT_APP_B2C_ID_KEY
export const emailKey = process.env.REACT_APP_B2C_EMAIL_KEY

export const B2CErrorsKey = "onBoardingAuthB2CErrors";
export const authorisingKey = "authorising";
export const forgotPasswordKey = "forgotPassword";

export const getItem = (key) => sessionStorage.getItem(key);

export const setItem = (key, value) => sessionStorage.setItem(key, value);

export const removeItem = (key) => sessionStorage.removeItem(key);

export const removeB2CParamItems = () => {
  sessionStorage.removeItem(codeKey);
  sessionStorage.removeItem(idKey);
  sessionStorage.removeItem(emailKey);
}