import { mobileModel, browserName } from "react-device-detect";
import { isNil } from "lodash";
import authentication from "../../auth/react-azure-adb2c";
import { ApiActions } from "../middleware/call-api";
import { ActionTypes } from "../reducers/user";

const getDeviceInfo = () => ({
  id: null,
  description: isNil(mobileModel)
    ? `${browserName}`
    : `${browserName} ${mobileModel}`,
});

const registerLoginOnboarding = () => {
  const idToken = authentication.getAccessToken();
  return ({
    [ApiActions.POST_TO_API]: {
      endpoint: "/hcf/registerLoginOnboarding",
      body: { data: { deviceInfo: getDeviceInfo(), idToken, invitationGuid: null } },
      types: [
        ActionTypes.REGISTERING_LOGIN,
        ActionTypes.REGISTERED_LOGIN,
        ActionTypes.REGISTERING_LOGIN_FAILED,
      ],
    },
  });
};

export default registerLoginOnboarding;