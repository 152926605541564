import React, { useEffect } from "react";
import authentication from "../../auth/react-azure-adb2c";
import DefaultPageLayout from "../components/default-page-layout";
import { SendStartedSignInEvent, SendStartedForgotPasswordEvent } from "../helpers/analytics";
import adb2cConfig from "../../auth/adb2cConfig";
import { getItem, forgotPasswordKey } from "../helpers/sessionStorage";

const LoginScreen = () => {
  useEffect(() => {
    if (!authentication.signedIn()) {
      if (getItem(forgotPasswordKey)) {
        SendStartedForgotPasswordEvent();
      } else {
        SendStartedSignInEvent();
      }
      authentication.login(adb2cConfig);
    }
  }, []);

  return (
    <DefaultPageLayout loading />
  );
}

export default LoginScreen;
