import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeScreen from "./screens/home-screen";
import ErrorScreen from "./screens/error-screen";
import DownloadAppScreen from "./screens/download-app-screen";
import LoginScreen from "./screens/login-screen";
import store from "../store";
import { loadAppInsights } from "./helpers/analytics";


class App extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_APP_INSIGHTS_ENABLED === "TRUE") {
      loadAppInsights();
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/" component={HomeScreen} />
            <Route exact path="/error" component={ErrorScreen} />
            <Route exact path="/login" component={LoginScreen} />
            <Route exact path="/download" component={DownloadAppScreen} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
