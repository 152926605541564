import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactInsightsPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
      connectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
      extensions: [reactInsightsPlugin],
  }
});

export const loadAppInsights = () => {
  appInsights.loadAppInsights();
};

const PARTNER_CODE = process.env.REACT_APP_WEBSITE_PARTNER_CODE;

export const SendClickedOnboardingAuthenticateEvent = () => {
  const eventName = `Opened ${PARTNER_CODE} Onboarding Portal`;
  appInsights.trackEvent({ name: eventName });
};

export const SendStartedSignUpEvent = () => {
  const eventName = `Started ${PARTNER_CODE} Snug account signup`;
  console.log("started sign up!!");
  appInsights.trackEvent({ name: eventName });
};

export const SendStartedSignInEvent = () => {
  const eventName = `Started ${PARTNER_CODE} Snug account login`;
  console.log("started log in!!");
  appInsights.trackEvent({ name: eventName });
};

export const SendStartedForgotPasswordEvent = () => {
  const eventName = `Started ${PARTNER_CODE} Snug forgot password flow`;
  console.log("started forgot password!!");
  appInsights.trackEvent({ name: eventName });
};

export const SendSuccessfullyAuthenticatedEvent = () => {
  const eventName = `Successfully Authenticated ${PARTNER_CODE} Snug Account`;
  console.log("authenticated!!");
  appInsights.trackEvent({ name: eventName });
};

export const SendAuthenticationFailedEvent = (errorMessage) => {
  const eventName = `Authenticating ${PARTNER_CODE} Snug Account Failed`;
  console.log("authenticating failed!!");
  const eventProperties = { Error: errorMessage };
  appInsights.trackEvent({ name: eventName }, eventProperties);
};

export const SendClickedAppDownloadEvent = (platform) => {
  const eventName = `Clicked ${PARTNER_CODE} Snug Download link`;
  console.log("clicked download!!");
  const eventProperties = { DownloadPlatform: platform };
  appInsights.trackEvent({ name: eventName }, eventProperties);
};
