import React from "react";
import { makeStyles } from "@material-ui/styles";
import LoadingState from "./loading-state";
import ScreenHeader from "./screen-header";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
}));

function DefaultPageLayout(props) {
  const { content, loading, error } = props;
  let ContentComponent = () => null;

  if (loading) {
    ContentComponent = (<LoadingState />);
  } else if (error) {
    ContentComponent = (<div>{error}</div>);
  } else {
    ContentComponent = content;
  }
  const classes = useStyles();
  return (
    <div>
      <ScreenHeader />
      <div className={classes.container}>
        {ContentComponent && ContentComponent}
      </div>
    </div>
  );
}

export default DefaultPageLayout;
