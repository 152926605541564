import curryConnector from "./connectors";

export const USER_STATE_KEY = "user";

const curry = (fn) => curryConnector(fn, USER_STATE_KEY);

export const ActionTypes = {
  REGISTERING_LOGIN: "REGISTERING_LOGIN",
  REGISTERED_LOGIN: "REGISTERED_LOGIN",
  REGISTERING_LOGIN_FAILED: "REGISTERING_LOGIN_FAILED",
};

const INITIAL_STATE = {
  registerLogin: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.REGISTERING_LOGIN:
      return {
        ...state,
        registerLogin: { inProgress: true, success: false, error: false, consented: false },
      };
    case ActionTypes.REGISTERED_LOGIN:
      return {
        ...state,
        registerLogin: action.payload.success ? {
          inProgress: false,
          success: true,
          error: false,
          consented: action.payload.hasConsented,
        } : {
          inProgress: false,
          success: false,
          consented: false,
          error: action.payload.error,
        },
      };
    case ActionTypes.REGISTERING_LOGIN_FAILED:
      return {
        ...state,
        registerLogin: {
          inProgress: false,
          success: false,
          consented: false,
          error: action.payload.error,
          exceptionError: "We were unable to log you into Snug",
        },
      };

    default:
      return state;
  }
};

export const getRegisterLoginState = curry(({ localState }) => localState.registerLogin);
