import curryConnector from "./connectors";

export const VALIDATE_CODE_STATE_KEY = "validateCode";

const curry = (fn) => curryConnector(fn, VALIDATE_CODE_STATE_KEY);

export const ActionTypes = {
  VALIDATING_CODE: "VALIDATING_CODE",
  VALIDATED_CODE: "VALIDATED_CODE",
  VALIDATING_CODE_FAILED: "VALIDATING_CODE_FAILED",
};

const INITIAL_STATE = {
  validateCodeStatus: { inProgress: false, success: false, error: false },
  codeValidated: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.VALIDATING_CODE:
    console.log("validating code");
    return {
        ...state,
        codeValidated: false,
        validateCodeStatus: { inProgress: true, success: false, error: false },
      };
    case ActionTypes.VALIDATED_CODE:
      console.log("validated code", action.payload);
      return {
        ...state,
        validateCodeStatus: { inProgress: false, success: true, error: false },
        codeValidated: action.payload.valid,
      };
    case ActionTypes.VALIDATING_CODE_FAILED:
      console.log("validating code failed");
      return {
        ...state,
        codeValidated: false,
        validateCodeStatus: { inProgress: false, success: false, error: true },
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const getValidateCodeStatus = curry(({ localState }) => localState.validateCodeStatus);

export const isCodeValidated = curry(({ localState }) => localState.codeValidated);
