import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

export default function LoadingState() {
  return (
    <Grid
      container
      justify="center"
      className="my-24"
    >
      <CircularProgress />
    </Grid>
  );
}
