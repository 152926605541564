import React from "react";
import { Icon, Button, Grid, CircularProgress, Tooltip } from "@material-ui/core";
import { isString } from "lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const DefaultButton = ({
  icon,
  label,
  onClick,
  loading,
  size = "small",
  disabled,
  tooltip,
  component: ConditionalWrapper = tooltip ? Tooltip : React.Fragment,
  className,
  ...other
}) => {
  const classes = useStyles();
  let IconComponent = () => null;

  if (isString(icon)) {
    IconComponent = () => <Icon>{icon}</Icon>;
  } else {
    IconComponent = () => icon;
  }
  const tooltipProps = tooltip ? { title: isString(tooltip) ? tooltip : label } : null;

  let loaderSize = 20;
  if (size === "medium") {
    loaderSize = 24;
  }

  return (
    <ConditionalWrapper {...tooltipProps}>
      <div className={className}>
        <Button variant="contained" onClick={onClick} size={size} disabled={loading || disabled} {...other}>
          <Grid container justify="center" alignItems="center" className="pointer-events-none">
            {loading && <div className={clsx("flex items-center", label && classes.icon)}><CircularProgress size={loaderSize} /></div>}
            {!loading && icon && <div className={clsx("flex items-center", label && classes.icon)}><IconComponent /></div>}
            {label}
          </Grid>
        </Button>
      </div>
    </ConditionalWrapper>
  );
};

DefaultButton.defaultProps = {
  size: "small",
};

export default DefaultButton;
