import React from "react";
import { get } from "lodash";
import DefaultPageLayout from "../components/default-page-layout";
import ErrorPage from "../components/error-page";
import { SendAuthenticationFailedEvent } from "../helpers/analytics";
import { getItem, B2CErrorsKey } from "../helpers/sessionStorage";

const ErrorScreen = () => {
  let b2cErrors = getItem(B2CErrorsKey);
  let errorDescription = null;
  if (b2cErrors != null) {
    b2cErrors = JSON.parse(`{"${b2cErrors.replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => (key === "" ? value : decodeURIComponent(value)));
    errorDescription = get(b2cErrors, "error_description", null);
    if (errorDescription !== null) {
      errorDescription = `{"${errorDescription.trim().replace(/\s+/g, '","').replace(/\+/g, " ").replace(/:\s/g, '": "')}"}`;
      errorDescription = JSON.parse(errorDescription);
      errorDescription = get(errorDescription, "AADB2C", "");
    }
  }

  SendAuthenticationFailedEvent(errorDescription);
  return (
    <DefaultPageLayout
      content={<ErrorPage />}
    />
  );
};

export default ErrorScreen;
