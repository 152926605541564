import { ApiActions } from "../middleware/call-api";

const hcfStaffConsentChange = (givesConsent) => {
  return ({
    [ApiActions.POST_TO_API]: {
      endpoint: `/hcf/hcfStaffConsent/${givesConsent}`,
      entityType: "HCF_STAFF_CONSENT",
    },
  });
};

export default hcfStaffConsentChange;