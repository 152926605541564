import { RSAA } from "redux-api-middleware";
import { padStart, get } from "lodash";
import authentication from "../../auth/react-azure-adb2c";

const API_TIMEOUT = 3000;

const ENV_API_URL = process.env.REACT_APP_API_BASE_URL;

const API_PREFIX = `${ENV_API_URL}api`;

export const ApiActions = {
  FETCH_FROM_API: "Fetch From API",
  SAVE_TO_API: "Save to API",
  POST_TO_API: "Post to API",
  DELETE_FROM_API: "Delete from API",
};

function addOtherParams(res, otherParams) {
  if (res === undefined) {
    return otherParams;
  }

  return res
    .text()
    .then((response) => (response
      ? { ...JSON.parse(response), ...otherParams }
      : { ...otherParams }));
}

function buildActionTypes(
  entityType,
  fetchingPrefix,
  fetchedPrefix,
  types,
  otherParams,
  loadMore = false,
) {
  let fetchedPrefixValue = fetchedPrefix;
  if (loadMore) fetchedPrefixValue += "_MORE";

  return [
    {
      type:
        types != null
          ? types[0]
          : `${fetchingPrefix}_${entityType.toUpperCase()}`,
      payload: (RSAAaction, state, response) => addOtherParams(response, otherParams),
    },
    {
      type:
        types != null
          ? types[1]
          : `${fetchedPrefixValue}_${entityType.toUpperCase()}`,
      payload: (RSAAaction, state, response) => addOtherParams(response, otherParams),
    },
    {
      type:
        types != null
          ? types[2]
          : `ERROR_${fetchingPrefix}_${entityType.toUpperCase()}`,
      payload: (RSAAaction, state, response) => addOtherParams(response, otherParams),
    },
  ];
}

function createApiMiddleware(
  actionConstant,
  defaultMethod,
  fetchingPrefix,
  fetchedPrefix,
) {
  return (store) => (next) => (action) => {
    const parameters = action[actionConstant];
    if (typeof parameters === "undefined") {
      return next(action);
    }

    const {
      entityType,
      types,
      method,
      body,
      endpoint,
      headers,
      bailout,
      loadMore,
      ...otherParams
    } = parameters;

    return store.dispatch({
      [RSAA]: {
        body,
        endpoint,
        headers,
        method: method || defaultMethod,
        bailout,
        types: buildActionTypes(
          entityType,
          fetchingPrefix,
          fetchedPrefix,
          types,
          otherParams,
          loadMore,
        ),
      },
    });
  };
}

function getTimezoneOffsetString() {
  const offset = new Date().getTimezoneOffset();
  return `${offset < 0 ? "+" : "-"}${padStart(
    parseInt(Math.abs(offset / 60), 10),
    2,
    "0",
  )}${padStart(parseInt(Math.abs(offset % 60), 10), 2, "0")}`;
}

// eslint-disable-next-line no-unused-vars
function addHeaders(callAPI, store) {
  const timezoneOffset = getTimezoneOffsetString();
  const token = authentication.getAccessToken();

  return {
    ...callAPI,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      options: { timeout: API_TIMEOUT },
      "X-Client-Timezone-Offset": timezoneOffset,
      ...callAPI.headers,
    },
  };
}

export const fetchFromApi = createApiMiddleware(
  ApiActions.FETCH_FROM_API,
  "GET",
  "LOADING",
  "LOADED",
);
export const saveToApi = createApiMiddleware(
  ApiActions.SAVE_TO_API,
  "PUT",
  "SAVING",
  "SAVED",
);
export const postToApi = createApiMiddleware(
  ApiActions.POST_TO_API,
  "POST",
  "CREATING",
  "CREATED",
);
export const deleteFromApi = createApiMiddleware(
  ApiActions.DELETE_FROM_API,
  "DELETE",
  "DELETING",
  "DELETED",
);

export const makeApiCall = (store) => (next) => (action) => {
  const callAPI = action[RSAA];

  if (typeof callAPI === "undefined") {
    return next(action);
  }

  const { endpoint, method, body, headers } = callAPI;

  if (!endpoint.startsWith(API_PREFIX)) {
    // This is a request to a external site - don't touch it
    return next(action);
  }

  const contentType = get(headers, ["Content-Type"], null);

  if (
    method !== "GET"
    && typeof body !== "string"
    && contentType !== "multipart/form-data"
  ) {
    callAPI.body = JSON.stringify(body);
  }

  const newCallAPI = addHeaders(callAPI, store);

  return next({
    [RSAA]: { ...newCallAPI },
  });
};
