import * as reduxModule from "redux";
import { createStore, applyMiddleware, compose } from "redux";
import { apiMiddleware } from "redux-api-middleware";
import thunk from "redux-thunk";
import { fetchFromApi, saveToApi, deleteFromApi, postToApi, makeApiCall } from "./app/middleware/call-api";
import createReducer from "./app/reducers/index";
import fixApiEndpoint from "./app/middleware/fix-api-endpoint";

// eslint-disable-next-line no-underscore-dangle
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = "@@redux/INIT";

const composeEnhancers = process.env.NODE_ENV !== "production"
  && typeof window === "object"
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  })
  : compose;

const store = createStore(
  createReducer(),
  composeEnhancers(
    applyMiddleware(
      thunk,
      fetchFromApi,
      saveToApi,
      postToApi,
      deleteFromApi,
      fixApiEndpoint,
      makeApiCall,
      apiMiddleware,
    ),
  ),
);

export default store;
