import React from "react";
import ReactDOM from "react-dom";
import { Provider as StoreProvider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import "./index.css";
import App from "./app/App";
import store from "./store";
import reportWebVitals from "./reportWebVitals";
import authentication from "./auth/react-azure-adb2c";
import adb2cConfig from "./auth/adb2cConfig";

authentication.initialize(adb2cConfig);
const themeModule = require(`./theme/${process.env.REACT_APP_WEBSITE_PARTNER_CODE}/themeConfig`);
authentication.run(() => {
  ReactDOM.render(
    <ThemeProvider theme={themeModule.theme}>
      <React.StrictMode>
        <StoreProvider store={store}>
          <App />
        </StoreProvider>
      </React.StrictMode>
    </ThemeProvider>,
    document.getElementById("root")
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
