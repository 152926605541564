import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import DefaultPageLayout from "../components/default-page-layout";
import DownloadAppPage from "../components/download-app-page";
import authentication from "../../auth/react-azure-adb2c";
import { getItem, codeKey, B2CErrorsKey } from "../helpers/sessionStorage";
import registerLoginOnboarding from "../actions/login";
import validateHcfStaffCodeForConsent from "../actions/validate-code";
import { isCodeValidated } from "../reducers/validate-code";

class DownloadAppScreen extends Component {
  constructor() {
    super();
    this.setState({ loginLoaded: false, validateCodeLoaded: false, redirect: null })
  }

  componentDidMount() {
    const { registerLoginOnboarding: registerLoginOnboardingAction, validateHcfStaffCodeForConsent: validateHcfStaffCodeForConsentAction } = this.props;
    if (authentication.signedIn()) {
      // don't care if registerLoginOnboarding fails, keep going anyway
      registerLoginOnboardingAction().finally(() => this.setState({ loginLoaded: true }));
      validateHcfStaffCodeForConsentAction(getItem(codeKey)).finally(() => this.setState({ validateCodeLoaded: true }));
    }
    else {
      this.setState({ loginLoaded: true, validateCodeLoaded: true, redirect: "/" });
    }
  }

  render() {
    const { codeValidated } = this.props;
    const hasErrors = !!getItem(B2CErrorsKey);
    if (this.state?.loginLoaded && this.state?.validateCodeLoaded) {
      return (
        this.state?.redirect ? <Redirect to={this.state.redirect} />
          : (
            <DefaultPageLayout
              loading={hasErrors}
              content={<DownloadAppPage
                isHcfStaffCode={codeValidated}
              />}
            />
          )
      );
    }
    else {
      return(
        <DefaultPageLayout loading />
      );
    }
  }
}

export default (connect((state) => ({
  codeValidated: isCodeValidated(state),
}),
{ registerLoginOnboarding, validateHcfStaffCodeForConsent, isCodeValidated })(DownloadAppScreen));
