const adb2cConfig = {
  // your B2C tenant
  tenant: process.env.REACT_APP_AZURE_B2C_TENANT,
  // the policy to use to sign in, can also be a sign up or sign in policy
  signInPolicy: process.env.REACT_APP_AZURE_B2C_SIGN_IN_FLOW,

  signUpPolicy: process.env.REACT_APP_AZURE_B2C_SIGN_UP_FLOW,

  forgotPasswordPolicy: process.env.REACT_APP_AZURE_B2C_PASSWORD_RESET_FLOW,

  applicationId: process.env.REACT_APP_AZURE_B2C_CLIENT_ID,
  // where MSAL will store state - localStorage or sessionStorage
  cacheLocation: "sessionStorage",
  // the scopes you want included in the access token
  scopes: [process.env.REACT_APP_AZURE_B2C_ACCESS_API_SCOPE],
  // optional, the URI to redirect to after logout
  postLogoutRedirectUri: window.location.origin,
  redirectUri: `${window.location.origin}/download`,
  instance: process.env.REACT_APP_AZURE_B2C_ACCESS_INSTANCE,
  validateAuthority: false,
};

export default adb2cConfig;
